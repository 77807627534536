import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Catalogue from './Components/Catalogue';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Catalogue />,
    },
    {
      path: "/catalogue",
      element: <Catalogue />,
    },
  ]);
  return (
    <RouterProvider router={router} />
  );
}

export default App;
