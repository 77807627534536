import React from "react";
import {
  Worker,
  Viewer,
  ViewMode,
  ScrollMode,
  SpecialZoomLevel,
} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import tulip from "../assets/pdf/Tulip Towers - Brochure v16.pdf";

const Catalogue = () => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Viewer
          fileUrl={tulip}
          initialPage={2}
          viewMode={ViewMode.SinglePage}
          scrollMode={ScrollMode.Vertical}
          defaultScale={SpecialZoomLevel.PageWidth}
        />
      </div>
    </Worker>
  );
};

export default Catalogue;
